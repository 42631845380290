<template>
<div>
    <div v-for="(item) in sequence" v-bind:key="item.id">
        <span :style="registercolor(item)">{{ item.mobile }}</span>
    </div>
</div>
</template>
<script>
export default {
    props:['mobiles'],
    data() {
        return {
            sequence:[]
        }
    },
    mounted() {
        this.setsequence();
    },
    watch:{
        mobiles(){
            this.setsequence()
        }
    },
    methods: {
        registercolor(item){
            if(item.isactive==1){
                return "color:blue;font-weight:bold"
            }else{
                return "color:black"
            }
        },
        setsequence(){
            this.sequence=[];
            if(this.mobiles?.length>0){
                this.mobiles?.forEach((element) => {
                    if(element.isactive==1){
                        this.sequence.push(element)
                    }
                });
                this.mobiles?.forEach((element) => {
                    if(element.isactive==0){
                        this.sequence.push(element)
                    }
                });
            }
        }
    },
}
</script>