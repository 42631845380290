<template>
<div>
<div class="col-md-12">
    <div class="row" style="margin-top:20px">
        <div class="col-md-6" >
            <h3 class="page-header">
            <i class="fa fa fa-user animated bounceInDown show-info"></i>
            Employee Report
            </h3>
        </div>
        <div class="col-md-6 ">
            <div class="row">
                <div class="col-md-10" >
                    <div class="row" style="padding-top:18px;justify-content:flex-end;" v-if="viewno==1">
                        <div  class="col-md-2" style="margin-right:25px;">             
                            <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                        </div>
                    </div>
                    <div class="row" style="padding-top:18px;justify-content:flex-end;" v-if="viewno==3">
                        <div  class="col-md-2" style="margin-right:25px;">             
                            <button @click="back()" class="btn btn-primary" >Back</button>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
</div>
    
    <template v-if="viewno==1">
    <div class="col-md-12">
      <div class="row">
            <div class="col-md-6">
                <div class="info-box  bg-success-dark  text-white">
                    <div class="info-icon bg-purple">
                        <i class="fa fa fa-user fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Total Active Employee: 
                            <span class="badge bg-danger" style="font-size:medium;">
                                {{ this.activecount}}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="info-box  bg-danger-dark  text-white">
                    <div class="info-icon bg-purple">
                        <i class="fa fa fa-user fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Total Employee:  
                            <span class="badge bg-success" style="font-size:medium;">
                                {{this.totalemp}}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
      </div>
    </div>
    <div class="row">
        <div class="col-md-10 pt-0 pb-10">
            <div class="form-horizontal">
                <div class="form-group">
                <label class="col-lg-4 co-md-3 control-label "> <b> Search Records</b></label>
                    <div class="col-lg-8 col-md-9 pull-right ">
                    <input id="myInput" onkeyup="searchTable()" type="text" class="form-control form-cascade-control input-small">
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="col-md-12 mt-20">
        <div class="row">
            <div class="col-md-2">
                <strong>Show Rec.</strong>
            </div>
            <div class="col-md-2">
                <select class="form-control col-mb-1" v-model="noofrec" id="rec">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="panel">
            <div class="panel-body">
                <table class="table table-bordered table-hover table-striped display" id="tblmanual">
                    <thead class="table-font">
                        <tr>
                            <th class="visible-lg">#</th>
                            <th>Vsdigi ID</th>
                            <th class="visible-lg">Name</th>
                            <th>Email </th>
                            <th>User ID </th>
                            <th class="visible-lg">Mobile No</th>
                            <th class="visible-lg">Address</th>
                             <th class="visible-lg">Created By</th>
                            <th class="visible-lg">Follow Up</th>
                            <th v-if="loginusercheck('17')">Edit</th>
                            <th v-if="loginusercheck('15')">Action</th>
                        </tr>
                    </thead>
                    <tbody class="table-font">
                        <tr v-for="(item,index) in employees" v-bind:key="item.id">
                            <td>{{index+1 }}</td>
                            <td>{{item.generatedid}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.username}}</td>
                            <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td>
                            <td><display-first-address :addresses="item.addressess"></display-first-address></td>
                            <td><div v-if="item.enteredby!=null">{{item.enteredby.name}}</div></td>
                            <td><a href="#" @click="getfollowedaccounts(item)">{{ getfollow(item)}}</a></td>
                            <td v-if="loginusercheck('17')">
                                <button type="button" class="btn btn-success btn-xs" @click="edit(item)">
                                    <i class="fa fa-edit"></i> Edit
                                </button>
                            </td>
                            <td v-if="loginusercheck('15')">
                                <button type="button"  @click="active(item)"  class="btn btn-xs">
                                    <div  class="bg-sheme btn-xs" v-if="item.active==0">Deactive</div>
                                    <div  class="grd-bg-ff5722 btn-xs" v-else><i class="fa fa-check"></i>Activate</div>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-md-12" style="margin-top: 20px">
                    <nav aria-label="Page navigation example" style="text-align: right !important">   
                    <paginate
                        style="margin: 0px !important; float: right"
                        :page-count="noofpages"
                        :click-handler="clickCallback"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'">
                    </paginate>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    </template>
    <employee-form v-if="viewno==2"></employee-form>
    <loan-account-followed v-if="viewno==3" :followedid="followedid"></loan-account-followed>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import EmployeeForm from './EmployeeForm.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue';
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue';
import Paginate from "vuejs-paginate";
import Constants from '../../components/utilities/Constants.vue';
import LoanAccountFollowed from '../loanFollowed/LoanAccountFollowed.vue';
export default {
    components:{EmployeeForm,DisplayFirstAddress,DisplayMobiles,Paginate,LoanAccountFollowed},
    mixins:[Constants],
    props:['id'],
    data(){
        return {
            activecount:0,
            totalemp:0,
            currentpage:1,
            totalpage:0,
            noofrec:10,
            followedloan:[],
            followedid:''
        }
    },
    computed:{
		...mapGetters([
		    'viewno','employees','loggedinuser','employeescount','edititem'
		]),
         noofpages(){
                return Math.ceil(parseInt(this.employeescount) / parseInt(this.noofrec))
            }
	},
    watch: {
        '$route.params.id'() {
            this.refresh()
        }
    },
    mounted(){
        this.refresh();
        console.log("props id"+this.$route.params.id)
	},
    methods:{
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh()
        },
        active(item){
            if(item.active==1){
                this.$store.commit('assignloadingstatus',1)
                 let parm = {column:'active',id:item.id,value:0};
                this.$http.post('api/user/updatefield',parm)
                .then((response) => {
                    this.$store.commit('updateappendemployees',response.data);
                    this.refresh();
                    this.$store.commit('assignloadingstatus',0)
                })
                .catch((err) => {
                    console.log(err)
                });
            }else{
                this.$store.commit('assignloadingstatus',1)
                let parm = {column:'active',id:item.id,value:1};
                this.$http.post('api/user/updatefield',parm)
                .then((response) => {
                    this.$store.commit('updateappendemployees',response.data);
                    this.$store.commit('assignloadingstatus',0)
                    this.refresh();
                })
                .catch((err) => {
                    console.log(err)
                });
            }
        },
        edit(item){
            this.$store.commit('assignedititem',item)
            this.$store.commit('assignviewno',2)
        },
        
        refresh(){
            let param={active:this.$route.params.id,isemployee:1,noofrec:this.noofrec,currentpage:this.currentpage}
            window.axios.post('api/users/fetch',param)
	    		.then(response=>this.processResponse(response.data))
	    		.catch(error=>console.log(error));
            window.axios.post('api/users/fetch/count',param)
	    		.then(response=>this.processempCountResponse(response.data))
	    		.catch(error=>console.log(error));
            window.axios.post('api/followed/userid/all')
	    		.then(response=>this.processempFollowedResponse(response.data))
	    		.catch(error=>console.log(error));
        },
        processResponse(data){
            this.$store.commit('assignemployees',data)
        },
        processempCountResponse(data){
            this.$store.commit('assignemployeescount',data)
        },
        processempFollowedResponse(data){
            this.followedloan=data
        },
        getfollow(item){
            if(this.followedloan!=null && this.followedloan.length>0 ){
                let found = this.followedloan.find((block)=>block.followedbyid==item.id)
                if(typeof found != 'undefined'){
                    return found.count
                }
            }
            return 0
        },
        getfollowedaccounts(item){
            if(this.loginusercheck(131)){
                this.followedid=item.id
                this.$store.commit('assignviewno',3)
            }else{
                alert("No rights to access")
            }
            
        },
        back(){
            this.$store.commit('assignviewno',1)
        },
        
    }
}
</script>

<style scoped>
.btn-primary:hover{
    text-decoration: none !important;
    background-color: #3a476b !important;
}
</style>
